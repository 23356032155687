<template>
  <v-app>
    <div class="d-flex align-center mb-1">
      <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Filter" dense clearable></v-text-field>

      <Filters />

      <div><es-btn :disabled="busy" @click="refresh" color="indigo">
          <fa icon="sync" /> Refresh
        </es-btn></div>

    </div>
    <v-card class='mb-6'>
      <v-data-table fixed-header height="72vh" sort-by='order_updated' loading-text='Loading items ...'
        no-data-text='No items found.' :must-sort='true' :search='search' :headers='headers' :items='items'
        :items-per-page='100' :loading='busy' :header-props="{ sortIcon: 'mdi-menu-down' }"
        :footer-props="{ 'disable-items-per-page': true }">
        <div v-show="busy" slot="progress" class="dot-windmill"></div>
        <template v-slot:[`item.order_updated`]="{ item }">
          {{ item.formattedOrderTime }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions d-flex">
            <section class="d-flex flex-column">
              <es-btn :title="`Copy Order ID to clipboard`" class="es-btn" color="primary"
                @click="copyToClipboard(item.order_id)">
                <fa icon="copy" />
              </es-btn>
              <es-btn :title="`Copy User ID to clipboard`" class="es-btn" color="primary"
                @click="copyToClipboard(item.user_id)">
                <fa icon="copy" />
              </es-btn>
            </section>

            <section class="d-flex flex-column">
              <es-btn :title="`Go to Sitting`" class="es-btn" color="orange"
                @click="navigateTo(`/sittings/${item.sitting_id}`)">
                <fa icon="school" />
              </es-btn>
              <es-btn :title="`Go to User Events`" class="es-btn" color="orange" @click="navigateToUserEvents(item)">
                <fa icon="users" />
              </es-btn>
            </section>

            <es-btn title="✔️ Proctor this Sitting" class="es-btn es-btn-copy white--text" color="secondary"
              @click="navigateToProctoring(item)">
              <fa icon="highlighter" />
            </es-btn>

          </div>
        </template>
      </v-data-table>
    </v-card>

    <small class="mb-8"><em>What happened to the old verion of the Proctoring page?</em> <a href="/proctoring-old">It
        moved over here</a>.</small>

    <ProctorSitting />

  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Filters from '@/components/proctoring/Filters'
import ProctorSitting from '@/components/proctoring/ProctorSitting'
import * as clipboardy from 'clipboardy'

export default {
  data: () => ({
    search: '',
    chosenItem: {},
  }),
  components: {
    Filters,
    ProctorSitting,
  },
  computed: {
    ...mapState('proctoringList', [
      'headers',
      'items',
      'busy',
    ]),
  },
  methods: {
    ...mapActions('proctoringList', [
      'getTier',
    ]),
    navigateTo(path) {
      this.$router.push(path)
    },
    navigateToUserEvents({ user_id, started_time }) {
      const date = new Date(started_time)
      const eventDate = `${date.getFullYear()},${date.getMonth()},${date.getDate()}`
      this.$router.push(`/user-events?uid=${user_id}&ed=${eventDate}`)
    },
    navigateToProctoring({ order_id }) {
      this.$router.push({ name: "Proctoring", params: { uuid: order_id }, query: this.$route.query })
    },
    async copyToClipboard(string) {
      await clipboardy.write(string);
      this.$store.dispatch('snackbar/snack', {
        mode: 'success',
        message: `✅ Copied to clipboard: <strong class="px-4">${string}</strong>`
      })
    },
    async refresh() {
      try {
        await this.$store.dispatch('proctoringList/fetchItems');
        const query = { ...this.$route.query };
        if(query.search) {
          this.$store.dispatch('proctoringList/searchItems', query.search);
        }
      } catch (error) {
        console.error("Error in refresh method:", error);
        // Handle the error appropriately, e.g., show a message to the user
      }
  }
,
  },
  watch: {
    search(value) {
      const query = { ...this.$route.query };
      if (query.search === value) return;
      if (value) {
        query.search = value;
      } else {
        delete query.search;
      }
      this.$router.push({ name: "Proctoring", params: this.$route.params, query: query });
  },
    $route: {
      handler() {
        this.search = this.$route.query.search
      },
      immediate: true
    }
  },
}
</script>

<style lang='scss' scoped>
.dot-windmill {
  position: absolute;
  top: 84px;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #220a5a;
  color: #220a5a;
  transform-origin: 5px 15px;
  -webkit-animation: dotWindmill 2s infinite linear;
  animation: dotWindmill 2s infinite linear;
}

.actions {
  text-align: right;
  margin-right: -20px;
}

.es-btn {
  font-size: 12px;
  padding: 7px;
  min-width: 32px;
  margin-left: 6px;
  margin-bottom: 2px;

  &:disabled {
    opacity: 0.2;
  }
}

.main-certificate-image {
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.15);
}

::v-deep {
  .v-text-field__details {
    display: none;
  }

  .v-application--wrap {
    min-height: auto;
  }

  .v-text-field .v-label {
    z-index: 100;
  }

  .v-data-table-header th {
    text-align: left;
    color: white !important;
    background-color: #220a5a !important;

    &:first-child {
      border-top-left-radius: 4px;
      padding-left: 34px;
    }

    &:last-child {
      border-top-right-radius: 4px;
    }

    &.active,
    & .v-data-table-header__icon {
      color: #ffe300 !important;
    }

    &.sortable {
      white-space: nowrap;
    }
  }

  .v-data-table tbody tr {
    &.expired {
      opacity: 0.3;
      background: #fee;
      cursor: not-allowed;
    }

    & td {
      padding-top: 18px !important;
      padding-bottom: 18px !important;

      &:first-child {
        padding-left: 34px;
        background-color: inherit
      }

      &:last-child {
        padding-right: 34px;
      }
    }
  }

  .v-data-footer__select {
    visibility: hidden;
  }
}
</style>

<template>
  <div class="d-flex">
    <v-menu offset-y v-for="filter in filters" :key="filter.label">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          small
          depressed
          v-bind="attrs"
          v-on="on"
          class="ml-1"
        >
          {{filter.label}}: {{filter.items[filter.selectedIndex].text}}
          <v-icon dark right>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item-group v-model="filter.selectedIndex" mandatory>
          <v-list-item
            v-for="(item, i) in filter.items"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

    </v-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    selection: {}
  }),
  computed: {
    ...mapState('proctoringList', [
      'certificateStatus',
      'filters',
      'busy',
      'itemsAreLoaded'
    ]),
  },
  watch: {
    filters: {
      deep: true, // https://vuejs.org/guide/essentials/watchers.html#deep-watchers
      handler() {
        const filterQuery = this.filters.reduce((accumulator, { value, selectedIndex, items }) => {
          const filterText = items[selectedIndex].text
          accumulator[value] = filterText.toLowerCase()
          return accumulator
        }, {})

        const query = {
          ...this.$route.query,
          ...filterQuery
        }
        const noQueryChanges = Object.entries(this.$route.query).toString() === Object.entries(query).toString()
        if (noQueryChanges) return

        const route = { name: "Proctoring", params: this.$route.params, query }

        // If the URL has any filter query params, push to history (so that browser back button works).
        // If the URL does not have any filter query params, replace the URL without creating a history entry.
        const filterValues = this.filters.map(({ value }) => value)
        const hasFilterQueryparameters = Object.keys(this.$route.query).some(parameter => filterValues.includes(parameter))

        if (hasFilterQueryparameters) this.$router.push(route)
        else this.$router.replace(route)
      },
    },
    '$route.query': {
      async handler() {
        // Set the selectedIndex for each filter according to the query params
        const updatedFilters = [...this.filters].map(filter => {
          const queryValue = this.$route.query[filter.value]
          if(queryValue === undefined) return filter
          const selectedIndex = filter.items.findIndex(item => item.text.toLowerCase() === queryValue)
          return {
            ...filter,
            selectedIndex: selectedIndex > -1 ? selectedIndex : 0
          }
        })
        this.$store.commit('proctoringList/setFilters', updatedFilters)
        
        // Case: When proctoring dialog is open
        // We don't want to fetch items if they are already loaded
        if (this.itemsAreLoaded && this.$route.params.uuid !== undefined) return       
        
        //Otherwise if there are any query params, fetch items
        if (Object.keys(this.$route.query).length)
          await this.$store.dispatch('proctoringList/fetchItems')

        if (this.$route.query.search) {
          this.$store.dispatch('proctoringList/searchItems', this.$route.query.search)
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-btn {
    font-family: "BritishCouncilSansW15-Headline", sans-serif;
    font-size: 12px;
    text-transform: none;
    letter-spacing: normal;
    padding: 0 7px 0 9px !important;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.15);
    &.v-btn--disabled.v-btn--has-bg{
      background-color: #220a5a !important;
    }
  }
}
</style>

<template>
  <v-dialog v-model="showProctoringDialog" persistent>
    <v-dialog
      content-class="hybrid-dialog"
      v-model="showHybridSittingDialog"
      persistent
      width="450px"
    >
      <v-card class="pa-2 d-flex flex-column" min-height="280px">
        <v-btn
          class="close-button"
          fab
          x-small
          depressed
          @click="closeHybridDialog"
          title="Cancel"
          ><v-icon dark>mdi-close</v-icon></v-btn
        >
        <section>
          <div class="hybrid-dialog-container">
            <h2>Are you sure?</h2>
            <p>
              This test was taken under
              <strong class="green-text">hybrid testing</strong> conditions.
            </p>
            <p>Please do not fail the sitting until further review.</p>
          </div>
          <hr class="border-1 border-top border-light" />
          <div class="d-flex justify-space-around pt-5">
            <v-btn
              dark
              class="mr-1 mb-2 px-14 light-grey"
              @click="closeHybridDialog"
            >
              Cancel</v-btn
            >
            <v-btn
              dark
              class="mr-1 mb-2 px-4 orange darken-1"
              @click="showHybridSittingDialog = false"
            >
              Invalidate anyway
            </v-btn>
          </div>
        </section>
      </v-card>
    </v-dialog>
    <v-card class="pa-6 d-flex flex-column" min-height="90vh">
      <v-btn
        class="close-button"
        fab
        dark
        x-small
        depressed
        color="grey"
        @click="close"
        title="Cancel"
        ><v-icon dark>mdi-close</v-icon></v-btn
      >
      <h1>
        <fa icon="highlighter" />
        Proctor this sitting
      </h1>
      <section class="d-flex justify-space-between ml-3 mr-5 pb-5">
        <div class="blue-grey--text text--lighten-1 mr-auto pr-2">
          <small>Status</small>
          <br />
          {{ selectedItem.proctoring_status }}
        </div>
        <div class="blue-grey--text text--lighten-1 mr-auto pr-2">
          <small>User ID</small>
          <br />
          {{ selectedItem.user_id }}
        </div>
        <div class="blue-grey--text text--lighten-1 mr-auto pr-2">
          <small>Sitting ID</small>
          <br />
          {{ selectedItem.sitting_id }}
        </div>
        <div class="blue-grey--text text--lighten-1 mr-auto pr-2">
          <small>Assessment Type</small>
          <br />
          {{ selectedItem.assessment_skills }}
        </div>
        <div class="blue-grey--text text--lighten-1 mr-auto pr-2">
          <small>Country</small>
          <br />
          {{ selectedItem.user_country }}
        </div>
        <div class="blue-grey--text text--lighten-1 mr-auto pr-2">
          <small>Code</small>
          <br />
          {{ selectedItem.voucher_code || "—" }}
        </div>
        <div class="blue-grey--text text--lighten-1 mr-auto pr-2">
          <small>Certificate</small>
          <br />
          {{ selectedItem.certificate_status }}
        </div>
        <div class="blue-grey--text text--lighten-1 mr-auto pr-2">
          <small>Tier</small>
          <br />
          {{ selectedItem.tier }}
        </div>
        <div class="blue-grey--text text--lighten-1 mr-auto pr-2">
          <small>Certificate Type</small>
          <br />
          {{ selectedItem.product_title }}
        </div>
      </section>

      <v-card elevation="2" class="mb-8">
        <v-card-text>
          <div
            v-if="selectedItem.hybrid_assessment"
            class="hybrid-sitting-text-container"
          >
            <fa
              class="fa-3x"
              color="#00953B"
              icon="exclamation-circle"
              size="2x"
            />
            <div class="ml-2">
              <p>
                This test was taken under hybrid testing conditions. <br />
                <strong>Please do not fail the sitting</strong> until further
                review.
              </p>
            </div>
          </div>
          <strong>Proctoring images</strong>
          <center v-if="isBusy">
            <div class="dot-windmill mt-12"></div>
          </center>
          <center
            v-if="selectedItem.noImagesFound"
            class="blue-grey--text text--lighten-1 my-16"
          >
            <fa icon="info-circle" />
            <strong>Sorry.</strong>
            There are no proctoring images for this sitting.
          </center>
          <div
            class="d-flex images-container proctoring-images-container mt-2"
            :class="{
              'hybrid-sitting-images': selectedItem.hybrid_assessment
              // 'free-voucher-sitting-images': selectedItem.voucher_code === '1VPROC2FR'
            }"
          >
            <img
              title="Main certificate image"
              class="image main-photo carousel-thumbnail"
              v-if="selectedItem.main_photo"
              :src="selectedItem.main_photo"
              @click="
                thumbnailClick(0, [
                  selectedItem.main_photo,
                  ...selectedItem.proctoring_data
                ])
              "
            />
            <div
              v-if="
                selectedItem.proctoring_data &&
                  selectedItem.proctoring_data.length
              "
            >
              <img
                :title="`Proctoring image ${index + 1}`"
                class="image carousel-thumbnail"
                v-for="({ thumbnail }, index) in selectedItem.proctoring_data"
                :key="thumbnail"
                :src="thumbnail"
                @click="
                  thumbnailClick(index + 1, [
                    selectedItem.main_photo,
                    ...selectedItem.proctoring_data
                  ])
                "
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card elevation="2" class="mb-8" v-if="violationImagesFound">
        <v-card-text>
          <strong>Violation images</strong>
          <section
            class="images-container violation-images-container mt-2"
            :class="{
              'hybrid-sitting-images': selectedItem.hybrid_assessment
              // 'free-voucher-sitting-images': selectedItem.voucher_code === '1VPROC2FR'
            }"
          >
            <img
              :title="`Violation image ${index + 1}`"
              class="image carousel-thumbnail"
              v-for="({ thumbnail }, index) in selectedItem.violation_data"
              :key="thumbnail"
              :src="thumbnail"
              @click="thumbnailClick(index, selectedItem.violation_data)"
            />
          </section>
        </v-card-text>
      </v-card>

      <v-card
        elevation="2"
        class="mb-8"
        v-if="
          selectedItem &&
            selectedItem.recording_data &&
            selectedItem.recording_data.length
        "
      >
        <v-card-text>
          <strong>Audio recordings</strong>
          <div
            class="d-flex mt-2 flex-wrap"
            v-if="
              selectedItem.recording_data && selectedItem.recording_data.length
            "
          >
            <div
              class="pt-2 pr-2"
              v-for="({ recording }, index) in selectedItem.recording_data"
              :key="index"
            >
              <audio
                controls
                class="audio-player"
                :src="recording"
                ref="audioPlayers"
              >
                <source :src="recording" type="audio/mp3" />
              </audio>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <v-card
        elevation="2"
        class="mb-8"
        v-if="
          selectedItem &&
            selectedItem.room_scan_data &&
            selectedItem.room_scan_data.length
        "
      >
        <v-card-text>
          <strong>Room Scan videos</strong>
          <div
            class="d-flex mt-2 flex-wrap"
            v-if="
              selectedItem.room_scan_data && selectedItem.room_scan_data.length
            "
          >
            <div
              class="pt-2 pr-2 video-player-container"
              v-for="({ room_scan_video },
              index) in selectedItem.room_scan_data"
              :key="index"
            >
              <p>{{ get_video_label(room_scan_video) }}</p>
              <video
                controls
                class="video-player"
                :src="room_scan_video"
                ref="videoPlayers"
              >
                <source :src="room_scan_video" type="audio/mp4" />
              </video>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <section class="mt-auto">
        <v-alert text dense type="error" class="mb-2" v-if="showSubmitWarning">
          Note: Submitting this status will
          <strong>cancel</strong>
          the selectedItem.
        </v-alert>

        <v-alert
          text
          dense
          type="warning"
          class="mb-2"
          v-if="sittingFailedProctoring"
        >
          Note: This sitting has failed proctoring. It can't be proctored again.
        </v-alert>

        <section class="d-flex align-center">
          <v-btn
            v-for="proctoringReason in proctoringReasons"
            :key="`btn-${proctoringReason.action}`"
            @click="showActions(proctoringReason)"
            :disabled="isBusy"
            :plain="
              isShowingButtons &&
                selectedProctoringReason.action !== proctoringReason.action
            "
            dark
            class="mr-1 mb-2 px-4"
            :class="proctoringReason.btnCss || 'teal'"
            >{{ proctoringReason.displayName }}</v-btn
          >
          <template v-if="selectedProctoringReason">
            <v-radio-group
              class="ml-4 mt-0 pt-0"
              v-model="selectedProctoringOption"
              dense
              row
            >
              <v-radio
                v-for="option in selectedProctoringReason.reasons"
                :key="`option_${option.key}`"
                :label="option.displayName"
                :value="option.key"
              ></v-radio>
            </v-radio-group>
          </template>
          <template v-if="!sittingFailedProctoring">
            <v-btn
              @click.prevent="submit('close')"
              :disabled="disableSubmitButton"
              class="ml-auto mr-1 mb-2 px-6 secondary"
              ><v-icon left>mdi-content-save</v-icon> Save and Close</v-btn
            >
            <v-btn
              @click.prevent="submit('next')"
              :disabled="disableSubmitButton || !hasNextItem"
              class="mb-2 px-6 primary"
              >{{ hasNextItem ? "Save and Next" : "Next item not found"
              }}<v-icon right>mdi-content-save-move</v-icon></v-btn
            >
          </template>
        </section>
      </section>
    </v-card>

    <Carousel
      :showCarousel.sync="showCarousel"
      :selectedImage.sync="selectedImage"
      :images="carouselImages"
    />
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Carousel from "@/components/proctoring/Carousel";

export default {
  components: {
    Carousel
  },
  data: function() {
    return {
      showHybridSittingDialog: false,
      showSubmitWarning: false,
      isShowingButtons: false,
      showCarousel: false,
      selectedImage: 0,
      carouselImages: [],
      selectedProctoringReason: null,
      selectedProctoringOption: undefined
    };
  },
  computed: {
    ...mapState("proctorSitting", [
      "showProctoringDialog",
      "selectedItemId",
      "hasNextItem",
      "sitting",
      "busy",
      "proctoringReasons"
    ]),
    ...mapState("proctoringList", ["items", "itemsAreLoaded"]),
    selectedItem() {
      if (!this.selectedItemId) return {};

      const foundItem = this.items.find(
        ({ order_id }) => order_id === this.selectedItemId
      );
      if (!foundItem) return {};

      return foundItem;
    },
    isBusy() {
      return this.busy || this.selectedItem?.loadingProctoringImages;
    },
    violationImagesFound() {
      return !!this.selectedItem?.violation_data?.length;
    },
    disableSubmitButton() {
      return this.isBusy || !this.selectedProctoringOption;
    },
    sittingFailedProctoring() {
      return this.selectedItem?.proctoring_status?.startsWith("Fail");
    }
  },
  methods: {
    ...mapActions("proctorSitting", ["loadItem", "saveProctoring"]),
    thumbnailClick(index, images) {
      this.carouselImages = images.map(row =>
        row.image ? row : { image: row }
      );
      this.selectedImage = index;
      this.showCarousel = true;
    },
    reset() {
      this.selectedProctoringOption = undefined;
      this.selectedProctoringReason = null;
      this.isShowingButtons = false;
    },
    toggle(action) {
      if (this[action] !== true) {
        this.reset();
        this[newAction] = true;
        this.isShowingButtons = true;
      } else {
        this.reset();
      }
    },
    showActions(proctoringReason) {
      this.selectedProctoringReason = proctoringReason;
      this.isShowingButtons = true;
    },
    close() {
      // Pause any currently playing audio elements
      if (this.$refs.audioPlayers) {
        this.$refs.audioPlayers.forEach(audio => {
          if (!audio.paused) {
            // Check if the audio is playing
            audio.pause();
            audio.currentTime = 0; // Reset the audio to the start
          }
        });
      }
      if (this.$refs.videoPlayers) {
        this.$refs.videoPlayers.forEach(video => {
          if (!video.paused) {
            // Check if the video is playing
            video.pause();
            video.currentTime = 0; // Reset the video to the start
          }
        });
      }
      this.$store.commit("proctorSitting/setShowProctoringDialog", false);
      this.$router.push({
        name: "Proctoring",
        params: {},
        query: this.$route.query
      });
    },
    closeHybridDialog() {
      this.invalidateOptions = null;
      this.showHybridSittingDialog = false;
    },
    submit(action) {
      const status = this.selectedProctoringOption;

      this.$store.dispatch("proctorSitting/saveProctoring", {
        status,
        loadNext: action === "next",
        reset: this.reset
      });

      if (action === "close") this.close();
      else {
        const index = this.items?.findIndex(
          ({ order_id }) => order_id === this.selectedItemId
        );
        const nextSelectedItem = this.items[index + 1] || false;
        if (nextSelectedItem)
          this.$router.push({
            name: "Proctoring",
            params: { uuid: nextSelectedItem.order_id },
            query: this.$route.query
          });
      }
    },
    get_video_label(video_url) {
      if (video_url.includes("_front.mp4")) return "Front";
      if (video_url.includes("_back.mp4")) return "Back";
      return "";
    }
  },
  watch: {
    showProctoringDialog() {
      this.reset();
    },
    invalidateOptions(value) {
      if (value && this.selectedItem.hybrid_assessment)
        this.showHybridSittingDialog = true;
      this.showSubmitWarning = !!value;
    },
    regenerateOptions(value) {
      this.showSubmitWarning = !!value;
    },
    /**
     * Use case: Navigated from a bookmarked or shared URL that has the uuid parameter
     * Wait until the items are loaded, then show the proctoring form modal
     */
    itemsAreLoaded(doneLoading) {
      if (!doneLoading) return;
      if (!this.$route.params.uuid) return;
      this.$store.dispatch("proctorSitting/loadSelectedItem", {
        selectedItemId: this.$route.params.uuid
      });
    },
    /**
     * Use case: URL changed by clicking a "Proctor this sitting" button on the proctoring list page
     * Items have already loaded, so just load the selected item
     */
    $route() {
      if (!this.itemsAreLoaded) return;
      if (!this.$route.params.uuid) {
        this.$store.commit("proctorSitting/setShowProctoringDialog", false);
        return;
      }
      this.$store.dispatch("proctorSitting/loadSelectedItem", {
        selectedItemId: this.$route.params.uuid
      });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
}
.video-player-container {
  width: 200px;

  & .video-player {
    width: 100%;
    height: auto;
  }
}
label {
  font-size: 12px;
  cursor: pointer;
}

.v-btn {
  text-transform: inherit;
  font-weight: bolder;

  &.v-btn--plain {
    opacity: 0.3;
    color: black;
  }
}

.close-button {
  position: absolute;
  top: 25px;
  right: 25px;
}

.images-container {
  min-height: 220px;
}

.light-grey {
  color: black;
  background-color: #e5e4e2 !important;
}

.green-text {
  color: #00953b;
}

.hybrid-dialog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hybrid-sitting-images {
  background: #c0df88;
  border: 2px solid #00953b;
  border-radius: 10px;
  padding: 5px;
}

.free-voucher-sitting-images {
  background: #fad5a5;
  border: 2px solid #ff5f1f;
  border-radius: 10px;
  padding: 5px;
}

.hybrid-sitting-text-container {
  display: flex;
  color: rgba(0, 0, 0, 0.87);
}

.hybrid-sitting-icon {
  padding: 2px;
}

.image {
  margin-right: 4px;
  height: 200px;

  &.main-photo {
    margin-right: 10px;
    border: 5px solid #d6d6d6;
    border-radius: 10px;
  }
}

.carousel-thumbnail {
  cursor: zoom-in;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.images-message {
  color: grey;
}

.dot-windmill {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #220a5a;
  color: #220a5a;
  transform-origin: 5px 15px;
  -webkit-animation: dotWindmill 2s infinite linear;
  animation: dotWindmill 2s infinite linear;
}

::v-deep {
  .v-messages {
    display: none;
  }

  .v-label {
    font-size: 14px;
  }
}
</style>

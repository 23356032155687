<template>
  <v-dialog v-model="dialog">
    <v-carousel v-model="model" height="90vh">
      <v-carousel-item v-for="{image} in images" :key="image">
        <v-sheet color="black" height="100%" class="pt-4 pb-12">
          <v-img contain height="100%" :src="image" />
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showCarousel: Boolean,
    selectedImage: Number,
    images: Array,
  },
  computed: {
    dialog: {
      get() {
        return this.showCarousel;
      },
      set(value) {
        this.$emit("update:showCarousel", value);
      },
    },
    model: {
      get() {
        return this.selectedImage;
      },
      set(value) {
        this.$emit("update:selectedImage", value);
      },
    },
  },
};
</script>
